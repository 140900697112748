import { useCallback, useMemo, useState, type FunctionComponent } from 'react';
import { routes as ROUTES, type Route } from '../../config/routes.config';
import { useNavBarStyles } from './NavBar.styles';
import type { NavBarProps } from './NavBar.types';
import { DropDown } from '../DropDown';
import { AppLogoSvg } from '../../assets/img/AppLogo';
import { useWindowSize } from '../../hooks';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  ToggleButton,
  type DialogOpenChangeData,
  type DialogOpenChangeEvent,
} from '@fluentui/react-components';
import { HamburgerSvg } from '../../assets/img/Hamburger';
import { HamburgerMenu } from '../HamburgerMenu';
import { NavLink } from 'react-router-dom';

const Navbar: FunctionComponent<NavBarProps> = (props) => {
  const classes = useNavBarStyles();
  const windowSize = useWindowSize();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const routes = useMemo(() => ROUTES, []);

  const isMobile = useMemo(() => {
    return Boolean((windowSize.width ?? 0) <= 700);
  }, [windowSize.width]);

  const onToggleMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const onMenuOpen = useCallback(
    (_: DialogOpenChangeEvent, data: DialogOpenChangeData) => {
      setOpenMenu(data.open);

      if (props.onMenuToggle) {
        props.onMenuToggle(data.open);
      }
    },
    []
  );

  return (
    <nav role="navigation" aria-label="Main navigation" className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.brandWrapper}>
          <NavLink end to={'/'}>
            <AppLogoSvg
              role="img"
              height="55"
              width="162"
              title="London Bridge Podiatry Logo"
              aria-label="London Bridge Podiatry Logo"
            />
          </NavLink>
        </div>
        {isMobile ? (
          <div className={classes.routes}>
            <Dialog modalType={'modal'} open={openMenu} onOpenChange={onMenuOpen}>
              <DialogTrigger disableButtonEnhancement>
                <ToggleButton
                  className={classes.hamburgerBtn}
                  appearance={'transparent'}
                  icon={<HamburgerSvg className={classes.hamburgerIcon} />}
                  aria-label={'Navigation Options'}
                  title={'Navigation Options'}
                />
              </DialogTrigger>
              <DialogSurface className={classes.dialog}>
                <DialogBody className={classes.dialogBody}>
                  {/* <DialogTitle className={classes.dialogTitle}>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="outline"
                        icon={<CloseCrossSvg className={classes.closeCrossIcon} />}
                      />
                    </DialogTrigger>
                  </DialogTitle> */}
                  <DialogContent className={classes.dialogContent}>
                    {routes.map((route: Route) => (
                      <HamburgerMenu
                        key={route.id}
                        route={route}
                        onClose={onToggleMenu}
                      />
                    ))}
                  </DialogContent>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>
        ) : (
          <div className={classes.routes}>
            {routes.map((route: Route) => (
              <DropDown key={route.id} route={route} />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export { Navbar };
