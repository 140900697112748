import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import type { NavBarStyles } from './NavBar.types';
import { shorthands } from '@griffel/react';

const useNavBarStyles = makeStyles<keyof NavBarStyles>({
  root: {
    width: '100%',
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: '30',
    height: LondonBridgePodiatryTokens.navBarHeight,
    backgroundColor: LondonBridgePodiatryTokens.navBarBGColor,
    paddingLeft: '11rem',
    paddingRight: '11rem',
    boxShadow: '0 4px 6px 0 rgb(0 0 0 / 5%)',

    '@media all and (max-width: 1472px) and (min-width: 1100px)': {
      paddingLeft: '7rem',
      paddingRight: '7rem',
    },

    '@media all and (max-width: 1099px)': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  wrapper: {
    ...shorthands.margin('auto'),
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  brandWrapper: {
    ...shorthands.margin('auto'),
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: '46%',

    '@media all and (max-width: 599px)': {
      marginRight: '0.25rem',
    },
  },
  routes: {
    ...shorthands.margin('auto'),
    ...shorthands.gap('8px'),
    lineHeight: '24px',
    fontSize: '18px',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '54%',
    alignItems: 'center',
  },
  seperator: {
    ...shorthands.margin('1rem', '1.25rem'),
    width: '1.5px',
    alignSelf: 'stretch',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255, 255, 255, 0.13)',
  },
  hamburgerBtn: {
    ...shorthands.borderRadius('8px'),
  },
  hamburgerIcon: {
    '> path': {
      fill: LondonBridgePodiatryTokens.navBarColor,
    },
  },
  dialog: {
    ...shorthands.padding('0', '8px'),
    ...shorthands.margin('0'),
    ...shorthands.borderRadius('0'),
    ...shorthands.gap('0'),
    // height: '100vh',

    height: 'calc(100vh - 66px)',
    top: '66px',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  dialogBody: {
    ...shorthands.gap('0'),
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  dialogContent: {
    height: '100%',
    paddingBottom: '16px',
  },
  closeCrossIcon: {
    width: '15px',
    height: '15px',

    '> path': {
      fill: '#b3b3b3',
    },
  },
});

export { useNavBarStyles };
