import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { usePodiatryChiropodyStyles } from './PodiatryChiropody.style';
import type { PodiatryChiropodyProps } from './PodiatryChiropody.types';
import {
  podiatry1Url,
  podiatry1WebpUrl,
  podiatry2Url,
  podiatry2WebpUrl,
  podiatry3Url,
  podiatry3WebpUrl,
} from '../../../assets/img';
import { Image } from '@fluentui/react-components';

const PodiatryChiropodyService: FunctionComponent<PodiatryChiropodyProps> = () => {
  const classes = usePodiatryChiropodyStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('PODIATRY_CHIROPODY', ['SERVICES'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Podiatry / Chiropody'}</h1>
        <p className={classes.paragraph}>
          {
            'If you have hard, painful layers of skin (calluses and corns), fragile nails or infected toenails, your podiatrist can help you reduce the discomfort.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'We will look at your overall health: often a skin or nail condition is linked to other podiatry related problems. If your posture, footwear or lifestyle needs adjustment, your podiatrist will help you with recommendations.'
          }
        </p>
        <h2 className={classes.subtitle}>
          {'What are corns and callus and what causes them?'}
        </h2>
        <p className={classes.paragraph}>
          {
            'When we walk or stand, our body weight is carried first on the heel and then on the ball of the foot where the skin is thicker to withstand the pressure. When this pressure becomes excessive, some areas of skin thicken and form corns and callus, as a protective response to the friction of skin rubbing against a bone, shoe or the ground.'
          }
        </p>{' '}
        <p className={classes.paragraph}>
          <strong>{'Callus'}</strong>
          {
            ' is an extended area of thickened, hard skin on the sole of the foot. It is usually symptomatic of an underlying problem such as a bony deformity, a particular style of walking or inappropriate footwear. Some people have a natural tendency to form callus because of their skin type. Elderly people have less fatty tissue in their skin and this can lead to callus forming on the ball of the foot.'
          }
        </p>
        <p className={classes.paragraph}>
          <strong>{'Corns'}</strong>
          {
            ' are caused by pressure or friction over bony areas, such as a joint, and they have a central core which may cause pain if it presses on a nerve.'
          }
        </p>
        <div className={classes.grid}>
          <picture>
            <source srcSet={podiatry1WebpUrl} type="image/webp" />
            <source srcSet={podiatry1Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over templates'}
              src={podiatry1Url}
            />
          </picture>
          <picture>
            <source srcSet={podiatry2WebpUrl} type="image/webp" />
            <source srcSet={podiatry2Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over template mold'}
              src={podiatry2Url}
            />
          </picture>
          <picture>
            <source srcSet={podiatry3WebpUrl} type="image/webp" />
            <source srcSet={podiatry3Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over assesment device'}
              src={podiatry3Url}
            />
          </picture>
        </div>
      </article>
    </main>
  );
};

export { PodiatryChiropodyService };
