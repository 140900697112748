import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useToenailSurgeryStyles } from './ToenailSurgery.style';
import type { ToenailSurgeryProps } from './ToenailSurgery.types';
import { toenailSurgeryUrl, toenailSurgeryWebpUrl } from '../../../assets/img';
import { Image } from '@fluentui/react-components';

const ToenailSurgeryService: FunctionComponent<ToenailSurgeryProps> = () => {
  const classes = useToenailSurgeryStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('TOENAIL_SURGERY', ['SERVICES'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Toenail Surgery'}</h1>
        <p className={classes.paragraph}>
          {
            'If you are reading this, then the chances are you are struggling with an ingrown toenail or another painful nail condition and are considering nail surgery as a solution. We often see and treat people suffering from problematic nails.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'The most common reasons why you may need toenail surgery include the following:'
          }
        </p>
        <h2 className={classes.subtitle}>{'1. Ingrowing Toenails:'}</h2>
        <p className={classes.paragraph}>
          {
            'An ingrowing toenail occurs when the corner or side of your toenail begins to grow into the soft flesh (sulcal tissue) and sets off an inflammatory response. It will usually become very painful, red, and swollen and may become infected with accompanying puss. It is usually the big toe that is affected.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'Causes of ingrowing toenails vary from genetics to poor footwear. Some people are born with nails where the lateral curvature of the nail plate is excessive or exaggerated, and the nail is more likely to grow into the surrounding skin. Other causes include poor nail cutting, nail picking, trauma, ill-fitting shoes, smoking, and medications that can cause the nail to change shape.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'Sometimes an ingrowing toenail can be treated at home, but when it becomes painful and infected, it should be seen by a Podiatrist who will advise the best treatment to alleviate pain and promote healing. Nail Surgery is often the best solution for recurrent ingrowing toenails.'
          }
        </p>
        <h2 className={classes.subtitle}>
          {'2. Deformed or Thickened Toenails (Gryphosis):'}
        </h2>
        <p className={classes.paragraph}>
          {
            'Deformed and thickened toenails can develop due to trauma and injury, practising certain sports involving lots of toe impact (football, running, rambling), and ill-fitting shoes. A fungal infection of the nail can also cause the nail to thicken. In the case of thickened nails, just a fraction of an extra millimetre thickness in nails can put excessive pressure on the nail bed. This causes soreness, sometimes a tissue breakdown under the nail, and then ulcerations. When nails are damaged or deformed, the nail can cut into the skin tissue or leave the nail bed exposed and sore raising the risk of infection.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'Podiatry treatment for this type of nail problem varies depending on how severely damaged the nail is. Nail surgery can be a good solution if other interventions are unsuccessful.'
          }
        </p>
        <h3 className={classes.sectiontitle}>{'What Does The Procedure Involve?'}</h3>
        <ul className={classes.list}>
          <li className={classes.item}>
            {
              'On the day of the procedure, you can carry on with your normal routine, including eating and drinking and taking any medications (unless you are told not to). After the procedure, put your feet up and take it easy for the rest of the day.'
            }
          </li>
          <li className={classes.item}>
            {
              'The toe is anaesthetised with a local anaesthetic applied at the base of the toe. This is the only painful part of the procedure and is no worse than an ordinary injection.'
            }
          </li>
          <li className={classes.item}>
            {
              'When the toe is completely numb an antiseptic solution will be applied to your toe to minimise the chance of infection. The offending nail or piece of the nail will then be removed.'
            }
          </li>
          <li className={classes.item}>
            {'A chemical solution (phenol) is applied to stop the nail from regrowing.'}
          </li>
          <li className={classes.item}>
            {
              'A protective dressing will be applied, and the operation is complete. The whole procedure is over and done within an hour (for one toe).'
            }
          </li>
          <li className={classes.item}>
            {
              'A follow-up appointment will be arranged with you so that the podiatrist can assess and re-dress the toe.'
            }
          </li>
        </ul>
        <p className={classes.paragraph}>
          <strong>
            {
              'It is always our preference not to perform a complete nail extraction if it can be avoided. However, in certain cases, we may advise this as the best treatment depending on your condition. In most cases, partial removal is performed.'
            }
          </strong>
        </p>
        <h3 className={classes.sectiontitle}>{'Does Nail Surgery Hurt?'}</h3>
        <p className={classes.paragraph}>
          {`It's normal to worry about how painful nail surgery might be. Reassuringly, the only slightly painful part is the injection used to administer anaesthetic. Once the toe is numb, you will not experience any pain while the nail is removed. After the procedure, when the anaesthetic wears off, some patients might experience discomfort, you will be advised to take painkillers the first days.`}
        </p>
        <h3 className={classes.sectiontitle}>
          {'How long does it take to recover from toenail removal?'}
        </h3>
        <p className={classes.paragraph}>
          {'Complete recovery from toenail surgery takes approximately 4-6 weeks.'}
        </p>
        <picture>
          <source srcSet={toenailSurgeryWebpUrl} type="image/webp" />
          <source srcSet={toenailSurgeryUrl} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Feet standing over assesment device'}
            src={toenailSurgeryUrl}
            width={'545px'}
            height={'361px'}
          />
        </picture>
      </article>
    </main>
  );
};

export { ToenailSurgeryService };
