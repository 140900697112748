import { makeStyles } from '@fluentui/react-components';
import type { BackToTopBtnStyles } from './BackToTopBtn.types';
import { shorthands } from '@griffel/react';
import { LondonBridgePodiatryTokens } from '../../theme/theme';

const useBackToTopBtnStyles = makeStyles<keyof BackToTopBtnStyles>({
  root: {
    ...shorthands.border('1px', 'solid', 'transparent'),
    ...shorthands.borderRadius('50%'),
    ...shorthands.transition([
      ['opacity', '0.4s', 'ease'],
      ['bottom', '0.4s', 'ease'],
    ]),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3.5rem',
    zIndex: '9999',
    height: '3.5rem',
    right: '1.25rem',
    cursor: 'pointer',
    position: 'fixed',
    userSelect: 'none',
    backgroundColor: LondonBridgePodiatryTokens.backToTopBtnBGColor,
    boxShadow: 'rgba(0, 0, 0, 0.265) 0px 0px 20px',
    WebkitTapHighlightColor: 'transparent',
  },
  icon: {
    ...shorthands.margin('0.95rem', 'auto', 'auto'),
    display: 'block',
    color: '#20232a',
    fontSize: '1.5em',
    paddingLeft: '0.1rem',
  },
});

export { useBackToTopBtnStyles };
