import { makeStyles, shorthands } from '@fluentui/react-components';
import type { RegisteredBodyStyles } from './RegisteredBody.types';

const useRegisteredBodyStyles = makeStyles<keyof RegisteredBodyStyles>({
  root: {
    // ...shorthands.border('1px', 'solid', 'gray'),
    ...shorthands.borderRadius('8px'),
    ...shorthands.padding('32px', '8px'),
    ...shorthands.gap('16px'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    minWidth: '150px',
    maxWidth: '250px',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
  },
  logo: {
    width: '64px',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px',
  },
  description: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

export { useRegisteredBodyStyles };
