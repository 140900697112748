import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import { useAboutStyles } from './About.styles';
import type { AboutProps } from './About.types';
import { Link } from '@fluentui/react-components';
import { CalendarAddFilledSvg } from '../../assets/img/CalendarAddFilled';
import { CallConnectingFilledSvg } from '../../assets/img/CallConnectingFilled';
import { EmailFilledSvg } from '../../assets/img/EmailFilled';
import { ChatFilledSvg } from '../../assets/img/ChatFilled';

const About: FunctionComponent<AboutProps> = () => {
  const classes = useAboutStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('ABOUT')} />
      <article className={classes.article}>
        <br />
        <h1 className={classes.title}>{'London Bridge Podiatry'}</h1>
        <h2 className={classes.tagline}>{'Give your feet the care they deserve'}</h2>
        <br />
        <p className={classes.text}>
          {
            'At London Bridge Podiatry, we provide high-quality Chiropody & Podiatry services for patients of all ages. Whether you need general podiatry, shockwave therapy, computerized gait analysis & custom made orthotics, verruca treatments, nail surgery or diabetic foot care, we can help you.'
          }
        </p>
        <p className={classes.text}>
          {
            'Our goal is to reduce any pain and discomfort on your feet and ankles and enhance your mobility and well-being. During your first visit, we will evaluate your condition and design a personalized treatment plan for you. We strive to improve the health, comfort and performance of your feet.'
          }
        </p>
        <br />
        <h2 className={classes.subtitle}>
          {'Call Us today to ask a question or book an appointment.'}
        </h2>
        <p className={classes.text}>
          <Link
            aria-label={'Book an appointment Online'}
            className={classes.link}
            href="/book">
            <CalendarAddFilledSvg className={classes.linkIcon} />
            <span className={classes.linkText}>{'Book Online'}</span>
          </Link>
        </p>
        <p className={classes.text}>
          <Link
            className={classes.link}
            aria-label={'Contact Us by Phone Call'}
            href="tel:+447761217588">
            <CallConnectingFilledSvg className={classes.linkIcon} />
            <span className={classes.linkText}>{'+44 7761 217588'}</span>
          </Link>
        </p>
        <p className={classes.text}>
          <Link
            className={classes.link}
            aria-label={'Contact Us by email'}
            href="mailto:info@londonbridgepodiatry.com">
            <EmailFilledSvg className={classes.linkIcon} />
            <span className={classes.linkText}>{'info@londonbridgepodiatry.com'}</span>
          </Link>
        </p>
        <p className={classes.text}>
          <Link
            aria-label={'Contact Us using WhatsApp'}
            className={classes.link}
            href="https://wa.me/447761217588?text=Hello,+I+would+like+to+book+an+appointment">
            <ChatFilledSvg className={classes.linkIcon} />
            <span className={classes.linkText}>{'WhatsApp'}</span>
          </Link>
        </p>
        <br />
        <h2 className={classes.subtitle} id="location-label">
          Location
        </h2>
        <p className={classes.text}>
          {
            'Our practice is located at Movement Rx, 169 Borough High Street, conveniently situated between Borough tube station, Borough Market and London Bridge.'
          }
        </p>
        <p className={classes.text}>
          {
            'Please note that entry to the practice is reserved to clients with a scheduled appointment.'
          }
        </p>
        <br />
        <iframe
          className={classes.map}
          title="Clinic Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.489863287753!2d-0.09290383419724761!3d51.50422831875596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876035a74e960bb%3A0xc6c65227b5a400c0!2sLondon%20Bridge%20Podiatry!5e0!3m2!1sen!2sie!4v1677343060569!5m2!1sen!2sie"
          width="100%"
          height="300"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
        <br />
        <br />
        <br />
      </article>
    </main>
  );
};

export { About };
