import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../../components';
import { getRouteMetaInfo } from '../../../../config/routes.config';
import { useVerrugaTreatmentAcidBasedStyles } from './VerrugaTreatmentAcidBased.style';
import type { VerrugaTreatmentAcidBasedProps } from './VerrugaTreatmentAcidBased.types';

const VerrugaTreatmentAcidBasedService: FunctionComponent<
  VerrugaTreatmentAcidBasedProps
> = () => {
  const classes = useVerrugaTreatmentAcidBasedStyles();

  return (
    <main className={classes.root}>
      <MetaInfo
        {...getRouteMetaInfo('VERRUCA_TREATMENT_ACID_BASED_TREATMENT', [
          'SERVICES',
          'VERRUCA_TREATMENT',
        ])}
      />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Verruca treatment'}</h1>
        <h2 className={classes.subtitle}>{'Acid-based treatment'}</h2>
        <p className={classes.paragraph}>
          {`Acid treatments contain an acid based ingredient that works to break down the verruca`}
        </p>
      </article>
    </main>
  );
};

export { VerrugaTreatmentAcidBasedService };
