import { type FunctionComponent } from 'react';
import { useInfoGridStyles } from './InfoGrid.styles';
import type { InfoGridProps } from './InfoGrid.types';
import { InfoCard } from '../InfoCard';
import {
  home1Url,
  home1WebpUrl,
  home2Url,
  home2WebpUrl,
  home3Url,
  home3WebpUrl,
  shockwaveUrl,
  shockwaveWebpUrl,
} from '../../assets/img';

const InfoGrid: FunctionComponent<InfoGridProps> = () => {
  const classes = useInfoGridStyles();

  return (
    <section className={classes.root}>
      <InfoCard
        illustration={home1Url}
        illustrationWebp={home1WebpUrl}
        title={'Biomechanical assessment / Orthotics'}
        description={`We examine your foot and lower limb to identify if your gait is causing pain. The process includes a detailed check-up, foot casting, and insole recommendations. We offer various orthotic options.`}
      />
      <InfoCard
        illustration={home3Url}
        illustrationWebp={home3WebpUrl}
        title={'Podiatry / Chiropody'}
        description={`If you're experiencing discomfort from calluses, corns, or nail issues, our podiatrists can help. We assess your overall health and provide recommendations for posture, footwear, or lifestyle adjustments if needed.`}
      />
      <InfoCard
        illustration={home2Url}
        illustrationWebp={home2WebpUrl}
        title={'Custom orthotics'}
        description={
          'Custom orthotics are tailored footwear accessories, cast from your feet by our podiatrists. The process includes a thorough examination, foot casting, and specific corrections like plantar heights and arch support. Materials are chosen based on your lifestyle and body type.'
        }
      />
      <InfoCard
        illustration={shockwaveUrl}
        illustrationWebp={shockwaveWebpUrl}
        title={'Shockwave Therapy'}
        description={`Shockwave therapy is a fast, effective and non-invasive treatment of injured soft tissues, such as Plantar fasciitis and Achilles tendonitis where an injury reaches a chronic non-healing state. A safe alternative to surgery or steroid and other treatment injections.`}
      />
    </section>
  );
};

export { InfoGrid };
