import { Helmet } from 'react-helmet-async';
import { useMemo, type FunctionComponent } from 'react';
import type { LocalBusinessStructuredDataProps } from './LocalBusiness.types';

const LocalBusinessStructuredData: FunctionComponent<
  LocalBusinessStructuredDataProps
> = () => {
  const structuredData = useMemo(() => {
    const data = {
      '@context': 'https://schema.org',
      '@type': 'HealthAndBeautyBusiness',
      image: [
        'https://londonbridgepodiatry.com/clinic.jpg',
        'https://londonbridgepodiatry.com/clinic-1.jpg',
        'https://londonbridgepodiatry.com/clinic-2.jpg',
      ],
      photo: [
        'https://londonbridgepodiatry.com/clinic.jpg',
        'https://londonbridgepodiatry.com/clinic-1.jpg',
        'https://londonbridgepodiatry.com/clinic-2.jpg',
      ],
      logo: 'https://www.londonbridgepodiatry.com/london-bridge-podiatry-logo.jpg',
      name: 'London Bridge Podiatry',
      slogan: 'Give your feet the care they deserve',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '169 Borough High St',
        addressLocality: 'London',
        addressRegion: 'London',
        postalCode: 'SE1 1HR',
        addressCountry: 'UK',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        reviewCount: '2',
      },
      review: [
        {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: 'Pablo Inda',
          },
        },
        {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: 'Aaron ofu',
          },
        },
        {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: 'dlambea89',
          },
        },
        {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: 'Yago Carballo',
          },
        },
      ],
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 51.5042283,
        longitude: -0.0929038,
      },
      url: 'https://www.londonbridgepodiatry.com/',
      telephone: '+447761217588',
      currenciesAccepted: ['GBP'],
      paymentAccepted: ['Cash', 'Credit Card'],
      priceRange: '$',
      bookingPage: 'https://www.londonbridgepodiatry.com/book/',
      tourBookingPage: 'https://www.londonbridgepodiatry.com/book/',
      smokingAllowed: false,
      publicAccess: false,
      keywords: [
        'podiatry',
        'foot',
        'feet',
        'clinic',
        'london bridge',
        'sports',
        'injury',
        'pain',
        'treatment',
        'diabetic',
        'surgery',
        'podiatrist',
        'chiropodist',
      ],
      // "openingHoursSpecification": [
      //   {
      //     "@type": "OpeningHoursSpecification",
      //     "dayOfWeek": [
      //       "Monday",
      //       "Tuesday"
      //     ],
      //     "opens": "11:30",
      //     "closes": "22:00"
      //   },
      //   {
      //     "@type": "OpeningHoursSpecification",
      //     "dayOfWeek": [
      //       "Wednesday",
      //       "Thursday",
      //       "Friday"
      //     ],
      //     "opens": "11:30",
      //     "closes": "23:00"
      //   },
      //   {
      //     "@type": "OpeningHoursSpecification",
      //     "dayOfWeek": "Saturday",
      //     "opens": "16:00",
      //     "closes": "23:00"
      //   },
      //   {
      //     "@type": "OpeningHoursSpecification",
      //     "dayOfWeek": "Sunday",
      //     "opens": "16:00",
      //     "closes": "22:00"
      //   }
      // ],
    };

    return JSON.stringify(data);
  }, []);

  return (
    <Helmet>
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
};

export { LocalBusinessStructuredData };
