import type { FunctionComponent } from 'react';
import {
  biomechanical1Url,
  biomechanical1WebpUrl,
  biomechanical2Url,
  biomechanical2WebpUrl,
  biomechanicalAssessmentOrthotics2Url,
  biomechanicalAssessmentOrthotics2WebpUrl,
  customMadeOrthotics2Url,
  customMadeOrthotics2WebpUrl,
} from '../../../assets/img';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useBiomechanicalAssessmentOrthoticsStyles } from './BiomechanicalAssessmentOrthotics.style';
import type { BiomechanicalAssessmentOrthoticsProps } from './BiomechanicalAssessmentOrthotics.types';
import { Image } from '@fluentui/react-components';

const BiomechanicalAssessmentOrthoticsService: FunctionComponent<
  BiomechanicalAssessmentOrthoticsProps
> = () => {
  const classes = useBiomechanicalAssessmentOrthoticsStyles();

  return (
    <main className={classes.root}>
      <MetaInfo
        {...getRouteMetaInfo('BIOMECHANICAL_ASSESMENT_ORTHOTICS', ['SERVICES'])}
      />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Biomechanical assessment / Orthotics'}</h1>
        <h2 className={classes.subtitle}>
          {'Experiencing pain in your feet or lower limb?'}
        </h2>
        <p className={classes.paragraph}>
          {`Pain in the foot and lower limb can be caused by many factors.`}
        </p>
        <p className={classes.paragraph}>
          {`Our objective, through a detailed examination of the foot and lower limb, is to identify if your gait is affecting the way that you walk thereby resulting in pain and discomfort.`}
        </p>
        <p className={classes.paragraph}>
          {
            'The assessment involves a detailed history and examination of the foot and lower leg with advice given on our findings.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'A cast is taken of the foot and advice is given regarding a suitable prescription for insoles.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'We provide different orthotic options, from over the counter orthotics to our more tailored, specialised insoles.'
          }
        </p>
        <h2 className={classes.subtitle}>{'Custom orthotics'}</h2>
        <picture>
          <source srcSet={customMadeOrthotics2WebpUrl} type="image/webp" />
          <source srcSet={customMadeOrthotics2Url} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Feet standing over assesment device'}
            src={customMadeOrthotics2Url}
            width={'545px'}
            height={'361px'}
          />
        </picture>
        <h2 className={classes.subtitle}>{'What is custom orthotics?'}</h2>
        <p className={classes.paragraph}>
          {`Custom orthotics are special tailored-made footwear accessories that are specially cast from the exact anatomy of your (non-weight bearing) feet by our podiatrists. It is different from over-the-counter insole because it is `}
          <strong>{'unique to your feet'}</strong>
          {'.'}
        </p>
        <p className={classes.paragraph}>
          {`Making custom orthotics is a multi-step process that includes first a thorough examination by our podiatrist, taking a cast of your foot on foam, and then sending the foam cast for manufacturing.`}
        </p>
        <p className={classes.paragraph}>
          {`Specific corrections can be prescribed by our podiatrists and manufactured into our custom orthotics - these include plantar heights and arch support, heel cup depth, even medical grade support can be specifically built into our custom orthotics.`}
        </p>
        <p className={classes.paragraph}>
          {`Most of our orthotics are made from high-grade thermoplastics. Cushioning materials will be added on top to complement the harder plastics and provide added comfort.`}
        </p>
        <p className={classes.paragraph}>
          {`Our podiatrist will take your lifestyle and body type into consideration when choosing materials for your orthotics.`}
        </p>
        <div className={classes.grid}>
          <picture>
            <source srcSet={biomechanicalAssessmentOrthotics2WebpUrl} type="image/webp" />
            <source srcSet={biomechanicalAssessmentOrthotics2Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over templates'}
              src={biomechanicalAssessmentOrthotics2Url}
            />
          </picture>
          <picture>
            <source srcSet={biomechanical1WebpUrl} type="image/webp" />
            <source srcSet={biomechanical1Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over template mold'}
              src={biomechanical1Url}
            />
          </picture>
          <picture>
            <source srcSet={biomechanical2WebpUrl} type="image/webp" />
            <source srcSet={biomechanical2Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over assesment device'}
              src={biomechanical2Url}
            />
          </picture>
          {/* <picture>
            <source srcSet={customMadeOrthotics1WebpUrl} type="image/webp" />
            <source srcSet={customMadeOrthotics1Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over assesment device'}
              src={customMadeOrthotics1Url}
            />
          </picture> */}
          {/* <picture className={classes.picture}>
            <source srcSet={biomechanicalAssessmentOrthoticsWebpUrl} type="image/webp" />
            <source srcSet={biomechanicalAssessmentOrthoticsUrl} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet stepping into a mold'}
              src={biomechanicalAssessmentOrthoticsUrl}
            />
          </picture> */}
          {/* <picture>
            <source srcSet={customMadeOrthotics2WebpUrl} type="image/webp" />
            <source srcSet={customMadeOrthotics2Url} type="image/jpeg" />
            <Image
              className={classes.picture}
              alt={'Feet standing over assesment device'}
              src={customMadeOrthotics2Url}
            />
          </picture> */}
        </div>
      </article>
    </main>
  );
};

export { BiomechanicalAssessmentOrthoticsService };
