import {
  webLightTheme,
  type Theme,
  themeToTokensObject,
} from '@fluentui/react-components';

type LondonBridgePodiatryTokensTypes = {
  backgroundColor: string;

  navBarColor: string;
  navBarSecondaryColor: string;
  navBarBGColor: string;
  navBarHeight: string;
  blueHighlight: string;
  backToTopBtnBGColor: string;

  // General
  textColor: string;

  // Error
  errorBGColor: string;
  errorColor: string;

  // Card
  cardBackgroundColor: string;

  // Hero
  heroBGColor: string;
  heroColor: string;

  maxContentWidth: string;
};

const londonBridgePodiatryLightTheme: Theme & LondonBridgePodiatryTokensTypes = {
  ...webLightTheme,
  backgroundColor: '#eefaf5',

  navBarColor: '#FFF',
  navBarSecondaryColor: '#cccccc',
  navBarBGColor: 'rgb(29,55,114)',
  navBarHeight: '66px',
  blueHighlight: '#61dafb',
  backToTopBtnBGColor: '#61dafb',

  // Card
  cardBackgroundColor: '#FFF',

  // General
  textColor: '#000',

  // Error
  errorBGColor: '#e93e60',
  errorColor: '#FFF',

  // Hero
  heroBGColor: '#282c34',
  heroColor: '#FFF',

  maxContentWidth: '1200px',
};

const LondonBridgePodiatryTokens = themeToTokensObject(londonBridgePodiatryLightTheme);

export { londonBridgePodiatryLightTheme, LondonBridgePodiatryTokens };
