import type { FunctionComponent } from 'react';
import { APP_NAME } from '../../config/env.config';
import { useFooterStyles } from './Footer.styles';
import type { FooterProps } from './Footer.types';
import { mergeClasses } from '@griffel/react';
import { NavLink } from 'react-router-dom';
import { getRouteMetaInfo } from '../../config/routes.config';

const Footer: FunctionComponent<FooterProps> = () => {
  const classes = useFooterStyles();
  const sitemapInfo = getRouteMetaInfo('SITEMAP');

  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <section className={classes.column}>
          <h2 className={classes.title}>{'Address'}</h2>
          <p className={classes.text}>
            {
              'Our practice is located at Movement Rx, 169 Borough High Street, conveniently situated between Borough tube station, Borough Market and London Bridge.'
            }
          </p>
          <p className={classes.text}>
            {
              'Please note that entry to the practice is reserved to clients with a scheduled appointment.'
            }
          </p>
          {/* <p className={classes.text}>
            <a className={classes.link} href="tel:+447761217588">{ '+44 7761 217588' }</a>
          </p> */}
        </section>
        <section className={classes.column}>
          <h2 className={classes.title}>About</h2>
          <p className={classes.text}>
            {
              'At London Bridge Podiatry, we provide high-quality Chiropody & Podiatry services for patients of all ages. Whether you need general podiatry, gait analysis & custom made orthotics, verruca treatments, nail surgery or diabetic foot care, we can help you.'
            }
          </p>
          <p className={classes.text}>
            {
              'Our goal is to reduce any pain and discomfort on your feet and ankles and enhance your mobility and well-being. During your first visit, we will evaluate your condition and design a personalized treatment plan for you. We strive to improve the health, comfort and performance of your feet.'
            }
          </p>
        </section>
      </div>
      <br />
      <NavLink end to={'/sitemap'} className={classes.hiddenLink}>
        <span>{sitemapInfo.title}</span>
      </NavLink>
      <br />
      <div className={mergeClasses(classes.content, classes.copyright)}>
        {`Copyright © ${new Date().getFullYear()} ${(APP_NAME ?? '')
          .replace(/\s/g, '')
          .toLowerCase()}.com`}
      </div>
    </footer>
  );
};

export { Footer };
