import { makeStyles } from '@fluentui/react-components';
import { shorthands } from '@griffel/react';
import type { InfoGridStyles } from './InfoGrid.types';

const useInfoGridStyles = makeStyles<keyof InfoGridStyles>({
  root: {
    ...shorthands.margin('0'),
    ...shorthands.padding('32px', ''),
    ...shorthands.gap('16px'),
    boxSizing: 'border-box',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    width: '100%',

    '@media all and (min-width: 600px) and (max-width: 1000px)': {
      gridTemplateColumns: '1fr 1fr',
    },

    '@media all and (max-width: 599px)': {
      gridTemplateColumns: '1fr',
    },
  },
});

export { useInfoGridStyles };
