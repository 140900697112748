import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { DropDownStyles } from './DropDown.types';

const useDropDownStyles = makeStyles<keyof DropDownStyles>({
  root: {},
  item: {
    ...shorthands.padding('0.5rem', '0.75rem'),
    ...shorthands.borderTop('2px', 'solid', 'transparent'),
    ...shorthands.borderBottom('2px', 'solid', 'transparent'),
    ...shorthands.transition([
      ['color', '0.2s', 'ease-out'],
      ['border-bottom-colo', '0.2s', 'ease-out'],
    ]),
    ...shorthands.margin('0'),
    userSelect: 'none',
    color: 'white',
    letterSpacing: '0.01em',
    backgroundColor: 'transparent',
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'hidden',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    flexGrow: '0',
    flexShrink: '0',
    lineHeight: '24px',
    fontSize: '18px',

    '&:hover': {
      color: LondonBridgePodiatryTokens.blueHighlight,
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: LondonBridgePodiatryTokens.blueHighlight,
      backgroundColor: 'transparent',
    },
    '&:hover:active': {
      color: LondonBridgePodiatryTokens.blueHighlight,
      backgroundColor: 'transparent',
    },

    '@media all and (max-width: 599px)': {
      ...shorthands.padding('0.75rem', '0.2rem', '0.75rem', '0.2rem'),
      fontSize: '0.95rem',
      borderTopWidth: '4px',
    },
  },
  innerItem: {
    ...shorthands.padding('0'),
  },
  isActive: {
    color: LondonBridgePodiatryTokens.blueHighlight,
    borderBottomColor: LondonBridgePodiatryTokens.blueHighlight,
    ...shorthands.overflow('hidden'),

    '@media all and (max-width: 599px)': {
      borderBottomColor: LondonBridgePodiatryTokens.blueHighlight,
    },
  },
  link: {
    color: LondonBridgePodiatryTokens.navBarColor,
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('0'),

    '&:hover': {
      color: LondonBridgePodiatryTokens.blueHighlight,
      borderBottomColor: LondonBridgePodiatryTokens.blueHighlight,

      '@media all and (max-width: 599px)': {
        borderBottomColor: LondonBridgePodiatryTokens.blueHighlight,
      },
    },
  },
  noLink: {
    color: LondonBridgePodiatryTokens.navBarColor,
    ...shorthands.overflow('hidden'),
  },
  menu: {
    backgroundColor: LondonBridgePodiatryTokens.navBarBGColor,
    ...shorthands.overflow('hidden'),
  },
  menuList: {},
});

export { useDropDownStyles };
