import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { AboutStyles } from './About.types';

const useAboutStyles = makeStyles<keyof AboutStyles>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    ...shorthands.padding('16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  article: {
    ...shorthands.padding('0', '16px'),
    ...shorthands.gap('16px'),
    ...shorthands.borderRadius('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: LondonBridgePodiatryTokens.maxContentWidth,
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '24px',
    },
  },
  tagline: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',
    textDecorationLine: 'overline',
    color: '#0075b3',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  subtitle: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  text: {
    ...shorthands.margin('0'),
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
    textAlign: 'justify',
  },
  link: {
    ...shorthands.gap('8px'),
    display: 'flex',
    alignItems: 'center',
    width: '500px',

    '@media all and (max-width: 599px)': {
      width: '300px',
    },

    '@media all and (max-width: 499px)': {
      width: '200px',
    },
  },
  linkIcon: {
    width: '26px',
    minWidth: '26px',
    maxWidth: '26px',
    height: '26px',
    minHeight: '26px',
    maxHeight: '26px',
  },
  linkText: {},
  map: {
    ...shorthands.border('0'),
    ...shorthands.overflow('hidden'),
    ...shorthands.borderRadius('8px'),
    width: '100%',
    height: '300px',
  },
});

export { useAboutStyles };
