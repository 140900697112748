import { makeStyles } from '@fluentui/react-components';
import { shorthands } from '@griffel/react';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import type { HamburgerMenuStyles } from './HamburgerMenu.types';

const useHamburgerMenuStyles = makeStyles<keyof HamburgerMenuStyles>({
  root: {
    ...shorthands.margin('0'),
    height: '100vh',
  },
  isActive: {},
  link: {
    ...shorthands.padding('8px', '12px'),
    width: '100%',
    position: 'relative',
    fontWeight: '400',
    lineHeight: '28px',
    fontSize: '18px',

    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: LondonBridgePodiatryTokens.textColor,
  },
  noLink: {
    ...shorthands.padding('8px', '12px'),
    width: '100%',
    position: 'relative',
    fontWeight: '400',
    lineHeight: '28px',
    fontSize: '18px',

    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: LondonBridgePodiatryTokens.textColor,
  },
  menu: {},
  menuList: {},
  list: {
    ...shorthands.padding('0'),
    listStyleType: 'none',
    listStyleImage: 'none',
  },
  innerList: {},
  item: {
    '> button': {
      fontWeight: '400',
      lineHeight: '28px',
      fontSize: '18px',
    },
  },
});

export { useHamburgerMenuStyles };
