import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../../components';
import { getRouteMetaInfo } from '../../../../config/routes.config';
import { useVerrugaTreatmentNeedlingStyles } from './VerrugaTreatmentNeedling.style';
import type { VerrugaTreatmentNeedlingProps } from './VerrugaTreatmentNeedling.types';

const VerrugaTreatmentNeedlingService: FunctionComponent<
  VerrugaTreatmentNeedlingProps
> = () => {
  const classes = useVerrugaTreatmentNeedlingStyles();

  return (
    <main className={classes.root}>
      <MetaInfo
        {...getRouteMetaInfo('VERRUCA_TREATMENT_VERRUCA_NEEDLING', [
          'SERVICES',
          'VERRUCA_TREATMENT',
        ])}
      />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Verruca treatment'}</h1>
        <h2 className={classes.subtitle}>{`Verruca Needling (Falknor's needling)`}</h2>
        <p className={classes.paragraph}>
          <strong>{`Falknor's needling procedure (Verrucae Needling)`}</strong>
          {` is an innovative and effective treatment for verruca (warts), which are benign types of the Human Papilloma Virus (HPV) group.`}
        </p>
        <p className={classes.paragraph}>
          {`It is a form of soft tissue therapy which is intended to break down the verruca and create a controlled inflammatory response which, in turn, stimulates the body's immune system to recognise the virus and destroy it.`}
        </p>
        <p className={classes.paragraph}>
          {`The treatment has been around for over 45 years (being first described in a medical journal in America in 1969) and is used routinely with great success by practitioners internationally.`}
        </p>
        <p className={classes.paragraph}>
          {`Needling is different from most other treatment modalities (such as caustics, which can entail dozens of applications to be effective) as it only requires one, or occasionally two, applications making this an ideal treatment for busy people.`}
        </p>
      </article>
    </main>
  );
};

export { VerrugaTreatmentNeedlingService };
