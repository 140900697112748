import { Image, Text } from '@fluentui/react-components';
import { useMemo, type FunctionComponent } from 'react';
import { hcpcUrl, hcpcWebpUrl, rcopUrl, rcopWebpUrl } from '../../assets/img';
import { useRegisteredBodyStyles } from './RegisteredBody.styles';
import { RegisteredBodyEntities, type RegisteredBodyProps } from './RegisteredBody.types';

const RegisteredBody: FunctionComponent<RegisteredBodyProps> = (props) => {
  const classes = useRegisteredBodyStyles();

  const [logo, webpLogo, title, shortDescription, size] = useMemo(() => {
    switch (props.entity) {
      case RegisteredBodyEntities.Hcpc:
        return [
          hcpcUrl,
          hcpcWebpUrl,
          'HCPC Registered',
          'The Health & Care Professions Council (HCPC) is the UK regulator for Health & Care Professions and are there to protect the public.',
          {
            width: '64px',
            height: '32px',
          },
        ];
      case RegisteredBodyEntities.RoyalCollegeOfPodiatry:
        return [
          rcopUrl,
          rcopWebpUrl,
          'Royal College of Podiatry',
          'The Royal College of Podiatry (RCPod) is the professional association and trade union for registered chiropodists and podiatrists in the United Kingdom.',
          {
            width: '64px',
            height: '64px',
          },
        ];
      default:
        return [
          '#',
          '#',
          '',
          '',
          {
            width: '64px',
            height: '64px',
          },
        ];
    }
  }, [props.entity]);

  return (
    <div className={classes.root}>
      <picture>
        <source srcSet={webpLogo} type="image/webp" />
        <source srcSet={logo} type="image/jpeg" />
        <Image
          src={logo}
          alt={props.entity}
          className={classes.logo}
          width={size.width}
          height={size.height}
        />
      </picture>
      <Text as="h2" className={classes.title}>
        {title}
      </Text>
      <Text as="p" className={classes.description}>
        {shortDescription}
      </Text>
    </div>
  );
};

export { RegisteredBody };
