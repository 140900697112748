import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useDiabeticFootAssessmentStyles } from './DiabeticFootAssessment.style';
import type { DiabeticFootAssessmentProps } from './DiabeticFootAssessment.types';
import { diabetic1WebpUrl, diabetic1Url } from '../../../assets/img';
import { Image } from '@fluentui/react-components';

const DiabeticFootAssessmentService: FunctionComponent<
  DiabeticFootAssessmentProps
> = () => {
  const classes = useDiabeticFootAssessmentStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('DIABETIC_FOOT_ASSESMENT', ['SERVICES'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Diabetic foot assessment'}</h1>
        <p className={classes.paragraph}>
          {`Diabetes affects your body's ability to absorb glucose from your blood stream. In a healthy body, the glucose is used in tissue cells (including the tissue that forms the skin of your feet), to fight infections and heal in a natural way. With diabetes, the elevated glucose levels also affect the nerves in your feet and legs.`}
        </p>
        <p className={classes.paragraph}>
          {`People with Diabetes have a higher chance of developing poor blood circulation and have reduced sensation in their feet. Small skin problems can turn into more severe lesions (wounds/ulcers) because your immune system doesn't perform its normal role. And, on top of that, you often wouldn't notice the problem because of reduced sensation. This is why is important for diabetic patients to have regular foot assessments.`}
        </p>
        <picture>
          <source srcSet={diabetic1WebpUrl} type="image/webp" />
          <source srcSet={diabetic1Url} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Feet standing over assesment device'}
            src={diabetic1Url}
            width={'545px'}
            height={'361px'}
          />
        </picture>
      </article>
    </main>
  );
};

export { DiabeticFootAssessmentService };
