import type { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useConditionsStyles } from './Conditions.styles';
import type { ConditionsProps } from './Conditions.types';
import { mergeClasses } from '@fluentui/react-components';

const Conditions: FunctionComponent<ConditionsProps> = () => {
  const classes = useConditionsStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('CONDITIONS')} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Common conditions that we treat include:'}</h1>
        <ul className={classes.list}>
          <li className={classes.item}>
            <NavLink
              end
              to={'/conditions/ingrown-toenails'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Ingrown Toenails'}</span>
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink
              end
              to={'/conditions/fungal-nail-infections'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Fungal Nail Infections'}</span>
            </NavLink>
          </li>
          <li className={classes.item}>{`Athlete's Foot`}</li>
          <li className={classes.item}>{'Calluses and Corns'}</li>
          <li className={classes.item}>{'Verrucas'}</li>
          <li className={classes.item}>{'Plantar fasciitis'}</li>
          <li className={classes.item}>{'Flat feet'}</li>
          <li className={classes.item}>{'Sports Injuries'}</li>
          <li className={classes.item}>{'Diabetic Foot'}</li>
        </ul>
      </article>
    </main>
  );
};

export { Conditions };
