import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../../theme/theme';
import { shorthands } from '@griffel/react';
import type { FungalNailInfectionsStyles } from './FungalNailInfections.types';

const useFungalNailInfectionsStyles = makeStyles<keyof FungalNailInfectionsStyles>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    ...shorthands.padding('0', '16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  article: {
    ...shorthands.padding('64px', '0'),
    ...shorthands.gap('16px'),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: LondonBridgePodiatryTokens.maxContentWidth,
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',
  },
  paragraph: {
    ...shorthands.margin('0'),
    ...shorthands.padding('8px', '0'),
    textAlign: 'justify',
    boxSizing: 'border-box',
    fontSize: '16px',
    letterSpacing: '0.01em',
  },
});

export { useFungalNailInfectionsStyles };
