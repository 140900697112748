import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import { bannerBgUrl } from '../../assets/img';
import type { HeroStyles } from './Hero.types';

const useHeroStyles = makeStyles<keyof HeroStyles>({
  root: {
    ...shorthands.margin('0', 'auto'),
    ...shorthands.borderRadius('0'),
    ...shorthands.gap('16px'),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: LondonBridgePodiatryTokens.heroBGColor,
    backgroundImage: `url('${bannerBgUrl}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    width: '100%',
    minHeight: '300px',
  },
  slider: {
    ...shorthands.borderRadius('4px'),
    ...shorthands.overflow('hidden'),
    boxSizing: 'border-box',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    zIndex: '1',
  },
  slide: {
    ...shorthands.transition('transform', '1000ms', 'ease-in-out'),
    top: '0',
    left: '0',
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100%',
  },
  content: {
    ...shorthands.padding('16px', '32px'),
    ...shorthands.gap('8px'),
    ...shorthands.borderRadius('8px'),
    ...shorthands.border('1px', 'solid', 'black'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgb(255 255 255 / 78%)',
    zIndex: '10',
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '24px',
    },
  },
  tagline: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',
    textDecorationLine: 'overline',
    color: '#0075b3',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  navigation: {
    ...shorthands.gap('8px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10',
    position: 'sticky',
    top: '80px',
  },
});

export { useHeroStyles };
