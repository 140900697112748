import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useIngrownToenailsStyles } from './IngrownToenails.style';
import type { IngrownToenailsProps } from './IngrownToenails.types';

const IngrownToenailsCondition: FunctionComponent<IngrownToenailsProps> = () => {
  const classes = useIngrownToenailsStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('INGROWN_TOENAILS_CONDITION', ['CONDITIONS'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>
          {'What is an ingrown toenail and how can it be treated?'}
        </h1>
        <p className={classes.paragraph}>
          {
            'An ingrowing toenail occurs when the corner or side of your toenail begins to grow into the soft flesh (sulcal tissue) and sets off an inflammatory response. It will usually become very painful, red, and swollen and may become infected with accompanying puss. It is usually the big toe that is affected. '
          }
        </p>
        <p className={classes.paragraph}>
          {
            'Causes of ingrowing toenails vary from genetics to poor footwear. Some people are born with nails where the lateral curvature of the nail plate is excessive or exaggerated, and the nail is more likely to grow into the surrounding skin. Other causes include poor nail cutting, nail picking, trauma, ill-fitting shoes, smoking, and medications that can cause the nail to change shape.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'Sometimes an ingrowing toenail can be treated at home, but when it becomes painful and infected, it should be seen by a Podiatrist who will advise the best treatment to alleviate pain and promote healing. Nail Surgery is often the best solution for recurrent ingrowing toenails.'
          }
        </p>
      </article>
    </main>
  );
};

export { IngrownToenailsCondition };
