import { makeStyles } from '@fluentui/react-components';
import { shorthands } from '@griffel/react';
import type { InfoCardStyles } from './InfoCard.types';
import { LondonBridgePodiatryTokens } from '../../theme/theme';

const useInfoCardStyles = makeStyles<keyof InfoCardStyles>({
  root: {
    ...shorthands.margin('0'),
    ...shorthands.padding('0'),
    ...shorthands.borderRadius('8px'),
    ...shorthands.gap('8px'),
    ...shorthands.overflow('hidden'),
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    justifySelf: 'center',
    flexDirection: 'column',
    width: '100%',
    minWidth: '200px',
    maxWidth: '400px',
    backgroundColor: LondonBridgePodiatryTokens.cardBackgroundColor,
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
  },
  illustrationContainer: {
    ...shorthands.margin('0'),
    ...shorthands.borderRadius('4px'),
    ...shorthands.gap('8px'),
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '200px',
  },
  illustration: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    ...shorthands.margin('0'),
    ...shorthands.padding('8px', '16px', '16px', '16px'),
    ...shorthands.gap('8px'),
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '15px',
    },
  },
  description: {
    ...shorthands.margin('0'),
    fontSize: '14px',
    lineHeight: '19x',
    fontWeight: '400',
    height: '100%',

    '@media all and (max-width: 599px)': {
      fontSize: '16px',
    },
  },
});

export { useInfoCardStyles };
