import { makeStyles } from '@fluentui/react-components';
import { shorthands } from '@griffel/react';
import type { BookingStyles } from './Booking.types';
import { LondonBridgePodiatryTokens } from '../../theme/theme';

const useBookingStyles = makeStyles<keyof BookingStyles>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  article: {
    ...shorthands.gap('16px'),
    ...shorthands.borderRadius('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '24px',
    },
  },
  tagline: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',
    textDecorationLine: 'overline',
    color: '#0075b3',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  subtitle: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  text: {
    ...shorthands.margin('0'),
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
  },
  link: {},
  frame: {
    ...shorthands.overflow('hidden'),
    width: '100%',
    height: '1500px',
  },
});

export { useBookingStyles };
