import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../../components';
import { getRouteMetaInfo } from '../../../../config/routes.config';
import { useVerrugaTreatmentCryotherapyStyles } from './VerrugaTreatmentCryotherapy.style';
import type { VerrugaTreatmentCryotherapyProps } from './VerrugaTreatmentCryotherapy.types';

const VerrugaTreatmentCryotherapyService: FunctionComponent<
  VerrugaTreatmentCryotherapyProps
> = () => {
  const classes = useVerrugaTreatmentCryotherapyStyles();

  return (
    <main className={classes.root}>
      <MetaInfo
        {...getRouteMetaInfo('VERRUCA_TREATMENT_CRYOTHERAPY', [
          'SERVICES',
          'VERRUCA_TREATMENT',
        ])}
      />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Verruca treatment'}</h1>
        <h2 className={classes.subtitle}>{'Cryotherapy'}</h2>
        <p className={classes.paragraph}>
          {`The warts are frozen with liquid nitrogen resulting in a cold burn. This invokes an immune response to the HPV virus.`}
        </p>
        {/* <picture>
          <source srcSet={quiropodyWebpUrl} type="image/webp" />
          <source srcSet={quiropodyUrl} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Feet standing over assesment device'}
            src={quiropodyUrl}
            width={'545px'}
            height={'361px'}
          />
        </picture> */}
      </article>
    </main>
  );
};

export { VerrugaTreatmentCryotherapyService };
