import { makeStyles } from '@fluentui/react-components';
import { shorthands } from '@griffel/react';
import type { LinksStyles } from './Links.types';

const useLinksStyles = makeStyles<keyof LinksStyles>({
  root: {
    ...shorthands.margin('0'),
    height: '100vh',
  },
  isActive: {},
  link: {
    ...shorthands.padding('8px', '12px'),
    width: '100%',
    position: 'relative',
    fontWeight: '400',
    lineHeight: '28px',
    fontSize: '18px',

    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    textDecorationLine: 'underline',
  },
  menu: {},
  menuList: {},
  list: {
    ...shorthands.padding('0'),
    listStyleType: 'none',
    listStyleImage: 'none',
  },
  innerList: {
    paddingLeft: '16px',
  },
  item: {
    '> button': {
      fontWeight: '400',
      lineHeight: '28px',
      fontSize: '18px',
    },
  },
});

export { useLinksStyles };
