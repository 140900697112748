import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useShockwaveStyles } from './Shockwave.style';
import type { ShockwaveProps } from './Shockwave.types';
import { shockwaveUrl, shockwaveWebpUrl } from '../../../assets/img';
import { Image } from '@fluentui/react-components';

const ShockwaveService: FunctionComponent<ShockwaveProps> = () => {
  const classes = useShockwaveStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('SHOCKWAVE_THERAPY', ['SERVICES'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'SHOCKWAVE THERAPY'}</h1>
        <h2 className={classes.subtitle}>{'Extracorporeal shockwave therapy (ESWT)'}</h2>
        <p className={classes.paragraph}>
          {`Shockwave Therapy is delivered using a device that generates shockwaves through your skin to the site of the injury, the body responds by increasing the blood circulation and metabolism in the impact area which in turn accelerates the body's own healing processes. The shockwaves break down injured tissue and calcifications.`}
        </p>
        <p className={classes.paragraph}>
          {`It has been extensively researched and is recommended by the National Institute for Clinical Excellence (NICE) for the treatment of Plantar Fasciitis. It has been shown to reduce pain levels and accelerate healing times.`}
        </p>
        <h2 className={classes.subtitle}>{'What can be treated?'}</h2>
        <p className={classes.paragraph}>
          {`There is evidence to support Shockwave Therapy in the treatment of:`}
        </p>
        <ul className={classes.list}>
          <li className={classes.item}>{'Plantar Fasciitis/Fasciosis (heel pain)'}</li>
          <li className={classes.item}>{'Achilles Tendon Injuries'}</li>
          <li className={classes.item}>{'Shin Pain'}</li>
          <li className={classes.item}>{'Mortons Neuroma'}</li>
          <li className={classes.item}>{'Heel Spurs'}</li>
          <li className={classes.item}>{'Osteoarthritis'}</li>
          <li className={classes.item}>{'Osgood-Schlatters'}</li>
        </ul>
        <h2 className={classes.subtitle}>{'Benefits:'}</h2>
        <ul className={classes.list}>
          <li className={classes.item}>{'No anaesthesia'}</li>
          <li className={classes.item}>{'Non invasive'}</li>
          <li className={classes.item}>{'No medication'}</li>
          <li className={classes.item}>{'No surgery'}</li>
          <li className={classes.item}>
            {
              'Fast treatment; 5-10 minutes per treatment, usually 3-6 sessions are required depending on severity and chronicity'
            }
          </li>
          <li className={classes.item}>{'Can resume daily activity immediately.'}</li>
          <li className={classes.item}>{'Minimal post treatment care'}</li>
        </ul>
        <h2 className={classes.subtitle}>{'How does shockwave therapy work?'}</h2>
        <ul className={classes.list}>
          <li className={classes.item}>
            {
              'The shockwave is delivered to the tissue via a compressed air impulse exerted by the hand piece.'
            }
          </li>
          <li className={classes.item}>
            {
              'The shockwave radiates out, extending to the entire area where pain occurs.'
            }
          </li>
          <li className={classes.item}>
            {'Usually 3-6 sessions are needed spread on a weekly basis.'}
          </li>
          <li className={classes.item}>
            {'Each treatment takes approximately 5-10 minutes to be delivered.'}
          </li>
          <li className={classes.item}>
            {
              'No anaesthetic is required and you can continue usual activities. (It is recommended to avoid pain provoking exercise for 48 hours following treatment)'
            }
          </li>
          <li className={classes.item}>
            {
              'No surgery or other therapy is required and treatment can usually start straight away'
            }
          </li>
        </ul>
        <h2 className={classes.subtitle}>
          {'List of contraindications or precautions:'}
        </h2>
        <ul className={classes.list}>
          <li className={classes.item}>
            {
              'Cortisone injections are not to be administered within the last 11 weeks prior to shockwave therapy treatment.'
            }
          </li>
          <li className={classes.item}>
            {
              'Avoid if taking oral anticoagulants/steroids 6 weeks prior to the treatment.'
            }
          </li>
          <li className={classes.item}>
            {
              'If you have a cardiac pacemaker please notify your podiatrist prior to treatment as shockwave therapy may interfere with this.'
            }
          </li>
          <li className={classes.item}>
            {'Presence of metal implants/replacements or tumour.'}
          </li>
          <li className={classes.item}>{'Severe osteoporosis'}</li>
          <li className={classes.item}>{'Growing plates'}</li>
          <li className={classes.item}>{'Infection'}</li>
          <li className={classes.item}>{'Pregnancy'}</li>
        </ul>
        <picture>
          <source srcSet={shockwaveWebpUrl} type="image/webp" />
          <source srcSet={shockwaveUrl} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Extracorporeal shockwave therapy (ESWT)'}
            src={shockwaveUrl}
            width={'545px'}
            height={'361px'}
          />
        </picture>
        <p className={classes.paragraph}>
          {`Shockwave therapy is a fast, effective and non-invasive treatment of injured soft tissues, such as Plantar fasciitis and Achilles tendonitis where an injury reaches a chronic non-healing state. A safe alternative to surgery or steroid and other treatment injections.`}
        </p>
      </article>
    </main>
  );
};

export { ShockwaveService };
