import { useCallback, type FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import type { Route } from '../../config/routes.config';
import type { HamburgerMenuProps } from './HamburgerMenu.types';
import { mergeClasses } from '@griffel/react';
import { useHamburgerMenuStyles } from './HamburgerMenu.styles';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@fluentui/react-components';

const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = (
  props: HamburgerMenuProps
) => {
  const classes = useHamburgerMenuStyles();

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props.onClose]);

  if (props.route.hide) {
    return <></>;
  } else {
    const menuItems = new Array(0);
    if (props.route.children && props.route.children.length > 0) {
      const dropdownItems = props.route.children.map((childRoute: Route) => {
        if (childRoute.children && childRoute.children.length > 0) {
          return (
            <HamburgerMenu
              key={childRoute.id}
              route={childRoute}
              isSubmenu={true}
              onClose={props.onClose}
            />
          );
        } else {
          if (childRoute.noLink) {
            return (
              <Text as={'p'} key={childRoute.id} className={classes.noLink}>
                {childRoute.name}
              </Text>
            );
          } else {
            return (
              <NavLink
                end
                to={childRoute.path}
                key={childRoute.id}
                onClick={onClose}
                className={({ isActive }) =>
                  mergeClasses(classes.link, isActive ? classes.isActive : '')
                }>
                <span>{childRoute.name}</span>
              </NavLink>
            );
          }
        }
      });

      menuItems.push(
        <AccordionItem value={props.route.id} key={props.route.id}>
          <AccordionHeader expandIconPosition="end" className={classes.item}>
            {props.route.name}
          </AccordionHeader>
          <AccordionPanel>
            <ul className={mergeClasses(classes.list, classes.innerList)}>
              <Accordion collapsible navigation="linear" multiple={false}>
                {dropdownItems}
              </Accordion>
            </ul>
          </AccordionPanel>
        </AccordionItem>
      );
    } else {
      if (props.route.noLink) {
        return (
          <Text as={'p'} key={props.route.id} className={classes.noLink}>
            {props.route.name}
          </Text>
        );
      } else {
        menuItems.push(
          <NavLink
            end
            to={props.route.path}
            key={props.route.id}
            onClick={onClose}
            className={({ isActive }) =>
              mergeClasses(classes.link, isActive ? classes.isActive : '')
            }>
            <span>{props.route.name}</span>
          </NavLink>
        );
      }
    }
    return (
      <Accordion collapsible navigation="linear" multiple={false}>
        {menuItems}
      </Accordion>
    );
  }
};

export { HamburgerMenu };
