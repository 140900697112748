import type { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useServicesStyles } from './Services.styles';
import type { ServicesProps } from './Services.types';
import { mergeClasses } from '@fluentui/react-components';

const Services: FunctionComponent<ServicesProps> = () => {
  const classes = useServicesStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('SERVICES')} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Services that we provide include:'}</h1>
        <ul className={classes.list}>
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/podiatry-chiropody'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Podiatry / Chiropody'}</span>
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/toenail-surgery'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Toenail Surgery'}</span>
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/fungal-nail-treatment'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Fungal nail treatment'}</span>
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/verruca-treatment'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Verruca treatment'}</span>
            </NavLink>

            <ul className={classes.list}>
              <br />
              <li className={classes.item}>
                <NavLink
                  end
                  to={'/services/verruca-treatment/information'}
                  className={({ isActive }) =>
                    mergeClasses(classes.link, isActive ? classes.isActive : '')
                  }>
                  <span>{'Information'}</span>
                </NavLink>
              </li>
              <li className={classes.item}>
                <NavLink
                  end
                  to={'/services/verruca-treatment/acid-based-treatment'}
                  className={({ isActive }) =>
                    mergeClasses(classes.link, isActive ? classes.isActive : '')
                  }>
                  <span>{'Acid-based treatment'}</span>
                </NavLink>
              </li>{' '}
              <li className={classes.item}>
                <NavLink
                  end
                  to={'/services/verruca-treatment/cryotherapy'}
                  className={({ isActive }) =>
                    mergeClasses(classes.link, isActive ? classes.isActive : '')
                  }>
                  <span>{'Cryotherapy'}</span>
                </NavLink>
              </li>{' '}
              <li className={classes.item}>
                <NavLink
                  end
                  to={'/services/verruca-treatment/verruca-needling'}
                  className={({ isActive }) =>
                    mergeClasses(classes.link, isActive ? classes.isActive : '')
                  }>
                  <span>{`Verruca Needling (Falknor's needling)`}</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/biomechanical-assessment-orthotics'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Biomechanical assessment / Orthotics'}</span>
            </NavLink>
          </li>{' '}
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/diabetic-foot-assessment'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Diabetic foot assessment'}</span>
            </NavLink>
          </li>
        </ul>
      </article>
    </main>
  );
};

export { Services };
