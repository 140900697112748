import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import { shorthands } from '@griffel/react';
import type { ErrorStyles } from './Error.types';

const useErrorStyles = makeStyles<keyof ErrorStyles>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    ...shorthands.padding('16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  card: {
    ...shorthands.padding('32px', '16px'),
    ...shorthands.gap('16px'),
    ...shorthands.borderRadius('8px'),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: LondonBridgePodiatryTokens.maxContentWidth,
    backgroundColor: LondonBridgePodiatryTokens.errorBGColor,
    color: LondonBridgePodiatryTokens.errorColor,
  },
  title: {
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',
  },
  message: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
  },
});

export { useErrorStyles };
