enum RegisteredBodyEntities {
  Hcpc = 'hcpc',
  RoyalCollegeOfPodiatry = 'rcop',
}

interface RegisteredBodyProps {
  entity: RegisteredBodyEntities;
}

interface RegisteredBodyStyles {
  root: string;
  logo: string;
  title: string;
  description: string;
}

export { type RegisteredBodyProps, type RegisteredBodyStyles, RegisteredBodyEntities };
