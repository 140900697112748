import type { FunctionComponent } from 'react';
import {
  fungalNailTreatmentServiceUrl,
  fungalNailTreatmentServiceWebpUrl,
} from '../../../assets/img';
import { MetaInfo } from '../../../components';
import { getRouteMetaInfo } from '../../../config/routes.config';
import { useFungalNailTreatmentStyles } from './FungalNailTreatment.style';
import type { FungalNailTreatmentProps } from './FungalNailTreatment.types';
import { Image } from '@fluentui/react-components';

const FungalNailTreatmentService: FunctionComponent<FungalNailTreatmentProps> = () => {
  const classes = useFungalNailTreatmentStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('FUNGAL_NAIL_TREATMENT', ['SERVICES'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Fungal nail treatment'}</h1>
        <p className={classes.paragraph}>
          {
            'Fungal nail infection is a common type of general foot care that it’s often overlooked. If left untreated, affected nails can be permanently damaged and infection can spread to other, previously healthy nails. Secondary infections can also occur in the surrounding skin.'
          }
        </p>
        <p className={classes.paragraph}>
          {
            'Fungus thrives in wet, warm areas. It may have been caused from use of a communal area such as a shower or public change room, or it may be caused by a trauma suffered to the nails. We can provide a clear diagnosis and we may send samples of the nail for culture if required. An appropriate fungal nail treatment plan will be created for you to improve the health and appearance of your toenails.'
          }
        </p>
        <p className={classes.paragraph}>
          {`It's important to come and speak to a podiatrist as soon as possible if you suspect of having fungal toenail infection.`}
        </p>
        <picture className={classes.picture}>
          <source srcSet={fungalNailTreatmentServiceWebpUrl} type="image/webp" />
          <source srcSet={fungalNailTreatmentServiceUrl} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Fungal nail picture'}
            src={fungalNailTreatmentServiceUrl}
            width={'858px'}
            height={'430px'}
          />
        </picture>
      </article>
    </main>
  );
};

export { FungalNailTreatmentService };
