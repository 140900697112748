import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../../theme/theme';
import { shorthands } from '@griffel/react';
import type { PodiatryChiropodyStyles } from './PodiatryChiropody.types';

const usePodiatryChiropodyStyles = makeStyles<keyof PodiatryChiropodyStyles>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    ...shorthands.padding('0', '16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  article: {
    ...shorthands.padding('64px', '0'),
    ...shorthands.gap('16px'),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: LondonBridgePodiatryTokens.maxContentWidth,
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',
  },
  subtitle: {
    ...shorthands.margin('0'),
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: '700',
  },
  paragraph: {
    ...shorthands.margin('0'),
    ...shorthands.padding('8px', '0'),
    textAlign: 'justify',
    boxSizing: 'border-box',
    fontSize: '16px',
    letterSpacing: '0.01em',
  },
  picture: {
    ...shorthands.borderRadius('8px'),
    ...shorthands.overflow('hidden'),
    ...shorthands.margin('0', 'auto'),
    objectFit: 'cover',
    alignSelf: 'center',
    justifySelf: 'center',
    width: '100%',
    maxHeight: '400px',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
  },
  grid: {
    ...shorthands.margin('0'),
    ...shorthands.padding('16px', '0'),
    ...shorthands.gap('16px'),
    boxSizing: 'border-box',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    width: '100%',
    height: 'fit-content',

    '@media all and (min-width: 600px) and (max-width: 900px)': {
      gridTemplateColumns: '1fr 1fr',
    },

    '@media all and (max-width: 599px)': {
      gridTemplateColumns: '1fr',
    },
  },
});

export { usePodiatryChiropodyStyles };
