import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../../../components';
import { getRouteMetaInfo } from '../../../../config/routes.config';
import { useVerrugaTreatmentInformationStyles } from './VerrugaTreatmentInformation.style';
import type { VerrugaTreatmentInformationProps } from './VerrugaTreatmentInformation.types';
import { verrugaUrl, verrugaWebpUrl } from '../../../../assets/img';
import { Image } from '@fluentui/react-components';

const VerrugaTreatmentInformationService: FunctionComponent<
  VerrugaTreatmentInformationProps
> = () => {
  const classes = useVerrugaTreatmentInformationStyles();

  return (
    <main className={classes.root}>
      <MetaInfo
        {...getRouteMetaInfo('VERRUCA_TREATMENT_INFORMATION', [
          'SERVICES',
          'VERRUCA_TREATMENT',
        ])}
      />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Verruca treatment'}</h1>
        <h2 className={classes.subtitle}>{'Information'}</h2>
        <p className={classes.paragraph}>
          {`Verruca are plantar warts that commonly occur on the soles of the feet or around the toe area. They are caused by the Human Papilloma Virus (HPV), which is contagious through direct person-to-person contact. There are various forms of HPV, which all relate to various parts of the human body`}
        </p>
        <h3 className={classes.sectiontitle}>{'What causes verruca?'}</h3>
        <p className={classes.paragraph}>
          {`The HPV virus is thought to thrive in moist, damp environments such as swimming pools, changing room floors and communal shower areas. It is possible to contract verruca simply by walking across the same floor area as someone with a verruca, especially if you have any small or invisible cuts or abrasions that make it easier for the virus to penetrate.`}
        </p>
        <h3 className={classes.sectiontitle}>{'Who gets them?'}</h3>
        <p className={classes.paragraph}>
          {`Verrucae are most commonly seen in children, teenagers and young adults - largely those who use communal changing rooms. It is possible to develop an immunity against the virus over time but most people remain susceptible, although some more than others.`}
        </p>
        <h3 className={classes.sectiontitle}>{'How do I know I have them?'}</h3>
        <p className={classes.paragraph}>
          {`The most common appearance is that of a small cauliflower-type growth on the soles of your feet with tiny black dots. If it is painful when you pinch the area (like when you squeeze a spot), you are likely to have a verruca. They can grow to 1cm in diameter and may spread into a cluster of small warts. If you are unsure, seek advice from a podiatrist.`}
        </p>
        <h3 className={classes.sectiontitle}>{'Are they painful?'}</h3>
        <p className={classes.paragraph}>
          {`Verrucas can be uncomfortable and painful if they develop on a weight bearing part of the foot. In addition, hard skin (callus) can form over the top of the verruca, increasing the discomfort in this area. There are also some strains of the virus that spread very quickly and can look unsightly.`}
        </p>
        <picture>
          <source srcSet={verrugaUrl} type="image/webp" />
          <source srcSet={verrugaWebpUrl} type="image/jpeg" />
          <Image
            className={classes.picture}
            alt={'Feet standing over assesment device'}
            src={verrugaUrl}
            width={'545px'}
            height={'361px'}
          />
        </picture>
      </article>
    </main>
  );
};

export { VerrugaTreatmentInformationService };
