import type { ComponentType } from 'react';
import {
  About,
  IngrownToenailsCondition,
  Conditions,
  Booking,
  Sitemap,
  Home,
} from '../containers';
import {
  BiomechanicalAssessmentOrthoticsService,
  DiabeticFootAssessmentService,
  FungalNailTreatmentService,
  PodiatryChiropodyService,
  Services,
  ShockwaveService,
  ToenailSurgeryService,
} from '../containers/services';
import {
  VerrugaTreatment,
  VerrugaTreatmentAcidBasedService,
  VerrugaTreatmentCryotherapyService,
  VerrugaTreatmentInformationService,
  VerrugaTreatmentNeedlingService,
} from '../containers/services/VerrugaTreatment';
import { APP_NAME } from './env.config';

type MetaInfoProps = Partial<
  Readonly<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: any[];
    lang: string;
    title: string;
    useTemplate?: boolean;
    defer: boolean;
    locale: string;
    description: string;
  }>
>;

type Route = Readonly<{
  id: string;
  path: string;
  name: string;
  hide?: boolean;
  noLink?: boolean;
  metaInfo: MetaInfoProps;
  children?: Array<Route>;
  Component: ComponentType;
}>;

const routes: Route[] = [
  {
    id: 'HOME',
    path: '/',
    name: 'Home',
    Component: Home,
    // Component: About, // TODO: Implement Home
    hide: true,
    metaInfo: {
      useTemplate: false,
      title: APP_NAME,
      description:
        'Expert care for your feet and ankles. We offer diagnosis, treatment and prevention of foot and ankle problems. Book an appointment today!',
    },
  },
  {
    id: 'BOOKING',
    path: '/book',
    name: 'Book Appointment',
    Component: Booking,
    hide: false,
    metaInfo: {
      title: 'Book Appointment',
      description:
        'Expert care for your feet and ankles. We offer diagnosis, treatment and prevention of foot and ankle problems. Book an appointment today!',
    },
  },
  {
    id: 'CONDITIONS',
    path: '/conditions',
    name: 'Conditions',
    Component: Conditions,
    metaInfo: {
      title: 'Conditions',
      description: 'Give your feet the care they deserve.',
    },
    children: [
      {
        id: 'INGROWN_TOENAILS_CONDITION',
        path: '/conditions/ingrown-toenails',
        name: 'Ingrown Toenails',
        Component: IngrownToenailsCondition,
        noLink: true,
        metaInfo: {
          title: 'Ingrown Toenails | Conditions',
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'FUNGAL_NAIL_INFECTION',
        path: '/conditions/fungal-nail-infections',
        name: 'Fungal Nail Infections',
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: 'Fungal Nail Infections | Conditions',
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'ATHLETES_FOOT',
        path: '/conditions/athletes-foot',
        name: `Athlete's Foot`,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Athlete's Foot | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'CALLUSES_AND_CORNS',
        path: '/conditions/calluses-and-corns',
        name: `Calluses and Corns`,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Calluses and Corns | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'VERRUCAS',
        path: '/conditions/verrucas',
        name: `Verrucas`,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Verrucas | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'PLANTAR_FASCIITIS',
        path: '/conditions/plantar-fasciitis',
        name: `Plantar fasciitis`,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Plantar fasciitis | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'FLAT_FEET',
        path: '/conditions/flat-feet',
        name: `Flat feet`,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Flat feet | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'SPORTS_INJURIES',
        path: '/conditions/sports-injuries',
        name: `Sports Injuries`,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Sports Injuries | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'DIABETIC_FOOT',
        path: '/conditions/diabetic-foot',
        name: `Diabetic Foot `,
        Component: Conditions, // TODO: Create custom Component
        noLink: true,
        metaInfo: {
          title: `Diabetic Foot | Conditions`,
          description: 'Give your feet the care they deserve.',
        },
      },
    ],
  },
  {
    id: 'SERVICES',
    path: '/services',
    name: 'Services',
    Component: Services,
    metaInfo: {
      title: 'Services',
      description: 'Give your feet the care they deserve.',
    },
    children: [
      {
        id: 'PODIATRY_CHIROPODY',
        path: '/services/podiatry-chiropody',
        name: 'Podiatry / Chiropody',
        Component: PodiatryChiropodyService,
        metaInfo: {
          title: 'Podiatry / Chiropody | Services',
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'TOENAIL_SURGERY',
        path: '/services/toenail-surgery',
        name: 'Toenail Surgery',
        Component: ToenailSurgeryService,
        metaInfo: {
          title: 'Toenail Surgery | Services',
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'SHOCKWAVE_THERAPY',
        path: '/services/shockwave-therapy',
        name: 'Shockwave Therapy',
        Component: ShockwaveService,
        metaInfo: {
          title: 'Shockwave Therapy | Services',
          description: 'Extracorporeal shockwave therapy (ESWT).',
        },
      },
      {
        id: 'FUNGAL_NAIL_TREATMENT',
        path: '/services/fungal-nail-treatment',
        name: 'Fungal nail treatment',
        Component: FungalNailTreatmentService,
        metaInfo: {
          title: 'Fungal nail treatment | Services',
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'VERRUCA_TREATMENT',
        path: '/services/verruca-treatment',
        name: 'Verruca treatment',
        Component: VerrugaTreatment,
        metaInfo: {
          title: 'Verruca treatment | Services',
          description: 'Give your feet the care they deserve.',
        },
        children: [
          {
            id: 'VERRUCA_TREATMENT_INFORMATION',
            path: '/services/verruca-treatment/information',
            name: 'Information',
            Component: VerrugaTreatmentInformationService,
            metaInfo: {
              title: 'Information | Verruca treatment | Services',
              description: 'Give your feet the care they deserve.',
            },
          },
          {
            id: 'VERRUCA_TREATMENT_ACID_BASED_TREATMENT',
            path: '/services/verruca-treatment/acid-based-treatment',
            name: 'Acid-based treatment',
            Component: VerrugaTreatmentAcidBasedService,
            metaInfo: {
              title: 'Acid-based treatment | Verruca treatment | Services',
              description: 'Give your feet the care they deserve.',
            },
          },
          {
            id: 'VERRUCA_TREATMENT_CRYOTHERAPY',
            path: '/services/verruca-treatment/cryotherapy',
            name: 'Cryotherapy',
            Component: VerrugaTreatmentCryotherapyService,
            metaInfo: {
              title: 'Cryotherapy | Verruca treatment | Services',
              description: 'Give your feet the care they deserve.',
            },
          },
          {
            id: 'VERRUCA_TREATMENT_VERRUCA_NEEDLING',
            path: '/services/verruca-treatment/verruca-needling',
            name: `Verruca Needling (Falknor's needling)`,
            Component: VerrugaTreatmentNeedlingService,
            metaInfo: {
              title: `Verruca Needling (Falknor's needling) | Verruca treatment | Services`,
              description: 'Give your feet the care they deserve.',
            },
          },
        ],
      },
      {
        id: 'BIOMECHANICAL_ASSESMENT_ORTHOTICS',
        path: '/services/biomechanical-assessment-orthotics',
        name: 'Biomechanical assessment / Orthotics',
        Component: BiomechanicalAssessmentOrthoticsService,
        metaInfo: {
          title: 'Biomechanical assessment / Orthotics | Services',
          description: 'Give your feet the care they deserve.',
        },
      },
      {
        id: 'DIABETIC_FOOT_ASSESMENT',
        path: '/services/diabetic-foot-assessment',
        name: 'Diabetic foot assessment',
        Component: DiabeticFootAssessmentService,
        metaInfo: {
          title: 'Diabetic foot assessment | Services',
          description: 'Give your feet the care they deserve.',
        },
      },
    ],
  },
  {
    id: 'ABOUT',
    path: '/about',
    name: 'Contact Us',
    Component: About,
    hide: false,
    metaInfo: {
      title: 'Contact Us',
      description:
        'Expert care for your feet and ankles. We offer diagnosis, treatment and prevention of foot and ankle problems. Book an appointment today!',
    },
  },
  {
    id: 'SITEMAP',
    path: '/sitemap',
    name: 'Sitemap',
    Component: Sitemap,
    hide: true,
    metaInfo: {
      title: 'Sitemap',
      description:
        'Expert care for your feet and ankles. We offer diagnosis, treatment and prevention of foot and ankle problems. Book an appointment today!',
    },
  },
];

/**
 * Finds a route from a List of routes with children
 *
 * @param list The list of Routes
 * @param id The Child ID
 * @param parents The List of parents to the child
 * @returns The Route
 */
const findChild = (
  list: Array<Route>,
  id: string,
  parents?: Array<string>
): Route | undefined => {
  if (parents && parents.length > 0) {
    const parentRoute = list.find((r) => r.id === parents[0]);
    if (parentRoute?.children && parentRoute.children.length > 0) {
      return findChild(parentRoute.children, id, parents.slice(1));
    } else {
      return parentRoute;
    }
  } else {
    return list.find((r) => r.id === id);
  }
};

/**
 * Gets the metadata for the Route
 *
 * @param id The ID of the Route
 * @param parents The parents of the route
 * @returns The Route Metadata
 */
const getRouteMetaInfo = (id: string, parents?: Array<string>): MetaInfoProps => {
  const route = findChild(routes, id, parents);
  return route?.metaInfo ?? {};
};

const isLocationValidRoute = (pathname: string): boolean => {
  // console.warn('TODO: Review this function to support child routes.');
  return routes.some((r) => r.path === pathname);
};

export { routes, getRouteMetaInfo, isLocationValidRoute };

export type { MetaInfoProps, Route };
