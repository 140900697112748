import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../theme/theme';
import type { FooterStyles } from './Footer.types';
import { shorthands } from '@griffel/react';

const useFooterStyles = makeStyles<keyof FooterStyles>({
  root: {
    ...shorthands.padding('3rem', '1.5rem'),
    ...shorthands.margin('auto'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: LondonBridgePodiatryTokens.navBarColor,
    backgroundColor: LondonBridgePodiatryTokens.navBarBGColor,
    fontSize: '1.15rem',
    width: '100%',

    '@media all and (max-width: 769px)': {
      fontSize: '1rem',
    },
  },
  content: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: '1200px',

    '@media all and (max-width: 449px)': {
      ...shorthands.gap('34px'),
      width: '100%',
      flexDirection: 'column',
    },
  },
  copyright: {
    textAlign: 'center',
    color: LondonBridgePodiatryTokens.navBarSecondaryColor,
    fontSize: '13px',
  },
  hiddenLink: {
    textAlign: 'center',
    color: LondonBridgePodiatryTokens.navBarBGColor,
    fontSize: '13px',
  },
  column: {
    ...shorthands.gap('8px'),
    display: 'flex',
    flexDirection: 'column',
    color: LondonBridgePodiatryTokens.navBarColor,
    width: '100%',
  },
  buttons: {},
  title: {
    ...shorthands.margin('0'),
    color: LondonBridgePodiatryTokens.navBarColor,
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',
  },
  text: {
    ...shorthands.margin('0'),
    color: LondonBridgePodiatryTokens.navBarColor,
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
  },
  link: {
    color: '#5ed5f5',
  },
});

export { useFooterStyles };
