import type { FunctionComponent } from 'react';
import { Links, MetaInfo } from '../../components';
import { getRouteMetaInfo, routes, type Route } from '../../config/routes.config';
import { useSitemapStyles } from './Sitemap.styles';
import type { SitemapProps } from './Sitemap.types';

const Sitemap: FunctionComponent<SitemapProps> = () => {
  const classes = useSitemapStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('SITEMAP')} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Sitemap:'}</h1>
        <ul className={classes.routes}>
          {routes.map((route: Route) => (
            <Links key={route.id} route={route} />
          ))}
        </ul>
      </article>
    </main>
  );
};

export { Sitemap };
