import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import { useBookingStyles } from './Booking.styles';
import type { BookingProps } from './Booking.types';

const Booking: FunctionComponent<BookingProps> = () => {
  const classes = useBookingStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('BOOKING')} />
      <article className={classes.article}>
        <iframe
          src="https://eubookings.nookal.com/bookings/company/BWUQR/WAWNQ"
          className={classes.frame}></iframe>
      </article>
    </main>
  );
};

export { Booking };
