import { type FunctionComponent } from 'react';
import { useHeroStyles } from './Hero.styles';
import type { HeroProps } from './Hero.types';
import { Button, Text } from '@fluentui/react-components';

const Hero: FunctionComponent<HeroProps> = () => {
  const classes = useHeroStyles();

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <Text as={'h1'} className={classes.title}>
          {'London Bridge Podiatry'}
        </Text>
        <Text as={'h2'} className={classes.tagline}>
          {'Give your feet the care they deserve'}
        </Text>
      </div>
      <nav className={classes.navigation}>
        <Button as="a" href={'/book'} appearance={'secondary'}>
          Book Appointment
        </Button>
      </nav>
    </section>
  );
};

export { Hero };
