import {
  Hero,
  InfoGrid,
  MetaInfo,
  RegisteredBody,
  RegisteredBodyEntities,
} from '../../components';
import type { FunctionComponent } from 'react';
import { getRouteMetaInfo } from '../../config/routes.config';
import { useHomeStyles } from './Home.styles';
import { Image, Link } from '@fluentui/react-components';
import {
  clinic1Url,
  clinic1WebpUrl,
  clinic2Url,
  clinic2WebpUrl,
  clinicUrl,
  clinicWebpUrl,
} from '../../assets/img';
import { CalendarAddFilledSvg } from '../../assets/img/CalendarAddFilled';
import { CallConnectingFilledSvg } from '../../assets/img/CallConnectingFilled';
import { EmailFilledSvg } from '../../assets/img/EmailFilled';
import { ChatFilledSvg } from '../../assets/img/ChatFilled';

const Home: FunctionComponent = () => {
  const classes = useHomeStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('HOME')} />
      <Hero />
      <section className={classes.content}>
        <h2 className={classes.subtitle}>About</h2>
        <p className={classes.text}>
          {
            'At London Bridge Podiatry, we provide high-quality Chiropody & Podiatry services for patients of all ages. Whether you need general podiatry, shockwave therapy, computerized gait analysis & custom made orthotics, verruca treatments, nail surgery or diabetic foot care, we can help you.'
          }
        </p>
        <p className={classes.text}>
          {
            'Our goal is to reduce any pain and discomfort on your feet and ankles and enhance your mobility and well-being. During your first visit, we will evaluate your condition and design a personalized treatment plan for you. We strive to improve the health, comfort and performance of your feet.'
          }
        </p>
        <h2 className={classes.subtitle}>
          {'Call Us today to ask a question or book an appointment.'}
        </h2>
        <section className={classes.contactGrid}>
          <p className={classes.text}>
            <Link
              aria-label={'Book an appointment Online'}
              className={classes.link}
              href="/book">
              <CalendarAddFilledSvg className={classes.linkIcon} />
              <span className={classes.linkText}>{'Book Online'}</span>
            </Link>
          </p>
          <p className={classes.text}>
            <Link
              className={classes.link}
              aria-label={'Contact Us by Phone Call'}
              href="tel:+447761217588">
              <CallConnectingFilledSvg className={classes.linkIcon} />
              <span className={classes.linkText}>{'+44 7761 217588'}</span>
            </Link>
          </p>
          <p className={classes.text}>
            <Link
              className={classes.link}
              aria-label={'Contact Us by email'}
              href="mailto:info@londonbridgepodiatry.com">
              <EmailFilledSvg className={classes.linkIcon} />
              <span className={classes.linkText}>{'info@londonbridgepodiatry.com'}</span>
            </Link>
          </p>
          <p className={classes.text}>
            <Link
              aria-label={'Contact Us using WhatsApp'}
              className={classes.link}
              href="https://wa.me/447761217588?text=Hello,+I+would+like+to+book+an+appointment">
              <ChatFilledSvg className={classes.linkIcon} />
              <span className={classes.linkText}>{'WhatsApp'}</span>
            </Link>
          </p>
        </section>
        <InfoGrid />
        <h2 className={classes.subtitle}>The Clinic</h2>
        <p className={classes.text}>
          {
            'Our practice is located at Movement Rx, 169 Borough High Street, conveniently situated between Borough tube station, Borough Market and London Bridge.'
          }
        </p>
        <p className={classes.text}>
          {
            'Please note that entry to the practice is reserved to clients with a scheduled appointment.'
          }
        </p>
        <div className={classes.clinic}>
          <picture>
            <source srcSet={clinicWebpUrl} type="image/webp" />
            <source srcSet={clinicUrl} type="image/jpeg" />
            <Image
              className={classes.clinicPicture}
              alt={'Clinic from the Inside'}
              src={clinicUrl}
              width={'300px'}
              height={'225px'}
            />
          </picture>
          <picture>
            <source srcSet={clinic1WebpUrl} type="image/webp" />
            <source srcSet={clinic1Url} type="image/jpeg" />
            <Image
              className={classes.clinicPicture}
              alt={'Clinic from the Bench'}
              src={clinic1Url}
              width={'300px'}
              height={'225px'}
            />
          </picture>
          <picture>
            <source srcSet={clinic2WebpUrl} type="image/webp" />
            <source srcSet={clinic2Url} type="image/jpeg" />
            <Image
              className={classes.clinicPicture}
              alt={'Clinic from the Outside'}
              src={clinic2Url}
              width={'300px'}
              height={'225px'}
            />
          </picture>
        </div>
        <h2 className={classes.subtitle}>Credentials</h2>
        <div className={classes.registeredBodies}>
          <RegisteredBody entity={RegisteredBodyEntities.Hcpc} />
          <RegisteredBody entity={RegisteredBodyEntities.RoyalCollegeOfPodiatry} />
        </div>
      </section>
    </main>
  );
};

export { Home };
