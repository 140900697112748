import { type FunctionComponent } from 'react';
import { useInfoCardStyles } from './InfoCard.styles';
import type { InfoCardProps } from './InfoCard.types';
import { Image, Text } from '@fluentui/react-components';

const InfoCard: FunctionComponent<InfoCardProps> = (props) => {
  const classes = useInfoCardStyles();

  return (
    <article className={classes.root}>
      <div className={classes.illustrationContainer}>
        <picture>
          <source srcSet={props.illustrationWebp} type="image/webp" />
          <source srcSet={props.illustration} type="image/jpeg" />
          <Image
            className={classes.illustration}
            alt={props.title}
            src={props.illustration}
          />
        </picture>
      </div>
      <div className={classes.content}>
        <Text as={'h2'} className={classes.title}>
          {props.title}
        </Text>
        <Text as={'p'} className={classes.description}>
          {props.description}
        </Text>
      </div>
    </article>
  );
};

export { InfoCard };
