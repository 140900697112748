import { makeStyles } from '@fluentui/react-components';
import { LondonBridgePodiatryTokens } from '../../../../theme/theme';
import { shorthands } from '@griffel/react';
import type { VerrugaTreatmentNeedlingStyles } from './VerrugaTreatmentNeedling.types';

const useVerrugaTreatmentNeedlingStyles = makeStyles<
  keyof VerrugaTreatmentNeedlingStyles
>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    ...shorthands.padding('0', '16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  article: {
    ...shorthands.padding('64px', '0'),
    ...shorthands.gap('16px'),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: LondonBridgePodiatryTokens.maxContentWidth,
  },
  title: {
    ...shorthands.margin('0'),
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: '700',
  },
  subtitle: {
    ...shorthands.margin('0'),
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: '700',
  },
  sectiontitle: {
    ...shorthands.margin('0'),
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '700',
  },
  paragraph: {
    ...shorthands.margin('0'),
    ...shorthands.padding('8px', '0'),
    textAlign: 'justify',
    boxSizing: 'border-box',
    fontSize: '16px',
    letterSpacing: '0.01em',
  },
  list: {
    ...shorthands.padding('0', '32px'),
    listStyleType: 'square',
  },
  item: {
    ...shorthands.padding('0.5rem', '0.75rem'),
    ...shorthands.borderTop('2px', 'solid', 'transparent'),
    ...shorthands.borderBottom('2px', 'solid', 'transparent'),
    ...shorthands.transition([
      ['color', '0.2s', 'ease-out'],
      ['border-bottom-colo', '0.2s', 'ease-out'],
    ]),
    userSelect: 'none',
    letterSpacing: '0.01em',
    backgroundColor: 'transparent',
    height: '100%',
    position: 'relative',
    flexGrow: '0',
    flexShrink: '0',
    lineHeight: '20px',
    fontSize: '18px',

    '&svg': {
      ...shorthands.margin('.063rem', '0', '0', '.55rem'),
      ...shorthands.transition('color', '0.2s', 'ease-out'),
      fontSize: '0.75em',
      color: 'rgba(255,255,255,.5)',
    },

    '@media all and (max-width: 599px)': {
      ...shorthands.padding('0.75rem', '0.2rem', '0.75rem', '0.2rem'),
      fontSize: '0.95rem',
      borderTopWidth: '4px',

      '&:nth-child(2)': {
        marginLeft: '0.5rem',
      },
    },
  },
  picture: {
    ...shorthands.borderRadius('8px'),
    ...shorthands.overflow('hidden'),
    ...shorthands.margin('32px', '0'),
    alignSelf: 'center',
    width: '100%',
  },
});

export { useVerrugaTreatmentNeedlingStyles };
