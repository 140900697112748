import type { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { MetaInfo } from '../../../../components';
import { getRouteMetaInfo } from '../../../../config/routes.config';
import { useVerrugaTreatmentStyles } from './VerrugaTreatment.styles';
import type { VerrugaTreatmentProps } from './VerrugaTreatment.types';
import { mergeClasses } from '@fluentui/react-components';

const VerrugaTreatment: FunctionComponent<VerrugaTreatmentProps> = () => {
  const classes = useVerrugaTreatmentStyles();

  return (
    <main className={classes.root}>
      <MetaInfo {...getRouteMetaInfo('VERRUCA_TREATMENT', ['SERVICES'])} />
      <article className={classes.article}>
        <h1 className={classes.title}>{'Verruga Treatment:'}</h1>
        <ul className={classes.list}>
          <br />
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/verruca-treatment/information'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Information'}</span>
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/verruca-treatment/acid-based-treatment'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Acid-based treatment'}</span>
            </NavLink>
          </li>{' '}
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/verruca-treatment/cryotherapy'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{'Cryotherapy'}</span>
            </NavLink>
          </li>{' '}
          <li className={classes.item}>
            <NavLink
              end
              to={'/services/verruca-treatment/verruca-needling'}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{`Verruca Needling (Falknor's needling)`}</span>
            </NavLink>
          </li>
        </ul>
      </article>
    </main>
  );
};

export { VerrugaTreatment };
