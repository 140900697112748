import { makeStyles, shorthands } from '@fluentui/react-components';
import type { HomeStyles } from './Home.types';
import { LondonBridgePodiatryTokens } from '../../theme/theme';

const useHomeStyles = makeStyles<keyof HomeStyles>({
  root: {
    backgroundColor: LondonBridgePodiatryTokens.backgroundColor,
    boxSizing: 'border-box',
    minHeight: '400px',
    width: '100%',
  },
  content: {
    ...shorthands.margin('0', 'auto'),
    ...shorthands.padding('16px'),
    ...shorthands.gap('24px'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: LondonBridgePodiatryTokens.maxContentWidth,
  },
  subtitle: {
    ...shorthands.margin('0'),
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '700',

    '@media all and (max-width: 599px)': {
      fontSize: '18px',
    },
  },
  text: {
    ...shorthands.margin('0'),
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
    width: '100%',
  },
  link: {
    ...shorthands.gap('8px'),
    ...shorthands.flex(1),
    display: 'flex',
    alignItems: 'center',
    // width: '500px',

    // '@media all and (max-width: 599px)': {
    //   width: '300px',
    // },

    // '@media all and (max-width: 499px)': {
    //   width: '200px',
    // },
  },
  linkIcon: {
    width: '26px',
    minWidth: '26px',
    maxWidth: '26px',
    height: '26px',
    minHeight: '26px',
    maxHeight: '26px',
  },
  linkText: {},
  clinic: {
    ...shorthands.gap('16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  clinicPicture: {
    ...shorthands.borderRadius('8px'),
    width: '300px',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
  },
  registeredBodies: {
    ...shorthands.gap('16px'),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',

    '@media all and (max-width: 400px)': {
      gridTemplateColumns: '1fr',
    },
  },
  contactGrid: {
    ...shorthands.gap('48px', '16px'),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    maxWidth: '600px',

    '@media all and (max-width: 700px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
    },
  },
});

export { useHomeStyles };
