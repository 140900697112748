import { useCallback, type FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import type { Route } from '../../config/routes.config';
import type { LinksProps } from './Links.types';
import { mergeClasses } from '@griffel/react';
import { useLinksStyles } from './Links.styles';

const Links: FunctionComponent<LinksProps> = (props: LinksProps) => {
  const classes = useLinksStyles();

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props.onClose]);

  if (props.route.hide) {
    return <></>;
  } else {
    const menuItems = new Array(0);
    if (props.route.children && props.route.children.length > 0) {
      const dropdownItems = props.route.children.map((childRoute: Route) => {
        if (childRoute.children && childRoute.children.length > 0) {
          return (
            <Links
              key={childRoute.id}
              route={childRoute}
              isSubmenu={true}
              onClose={props.onClose}
            />
          );
        } else {
          if (childRoute.noLink) {
            return <></>;
          } else {
            return (
              <li key={childRoute.id} className={classes.item}>
                <NavLink
                  end
                  to={childRoute.path}
                  key={childRoute.id}
                  onClick={onClose}
                  className={({ isActive }) =>
                    mergeClasses(classes.link, isActive ? classes.isActive : '')
                  }>
                  <span>{childRoute.name}</span>
                </NavLink>
              </li>
            );
          }
        }
      });

      if (!props.route.noLink) {
        menuItems.push(
          <li key={props.route.id} className={classes.item}>
            <NavLink
              end
              to={props.route.path}
              key={props.route.id}
              onClick={onClose}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{props.route.name}</span>
            </NavLink>
          </li>
        );
      }

      menuItems.push(
        <li key={props.route.id} className={classes.item}>
          <ul className={mergeClasses(classes.list, classes.innerList)}>
            {dropdownItems}
          </ul>
        </li>
      );
    } else {
      if (!props.route.noLink) {
        menuItems.push(
          <li key={props.route.id} className={classes.item}>
            <NavLink
              end
              to={props.route.path}
              key={props.route.id}
              onClick={onClose}
              className={({ isActive }) =>
                mergeClasses(classes.link, isActive ? classes.isActive : '')
              }>
              <span>{props.route.name}</span>
            </NavLink>
          </li>
        );
      }
    }
    return <>{menuItems}</>;
  }
};

export { Links };
